import * as React from "react"

import pipeline from "../images/pipeline.mp4"
import eda from "../images/eda-trimmed.mp4"
import copyCodeCellToHover from "../images/copy-code-to-cell-hover.mp4"
import dataQuality from "../images/auto-test.mp4"
import dbtDocs from "../images/dbt-docs.mp4"
import dabbing from "../images/cute-monkey-dabbing.png"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "./index.scss"

const IndexPage = () => {
  // const [isOpen, setOpen] = React.useState(false)

  return (
    <Layout>
      <Seo title="Home" />
      {/* {typeof window !== "undefined" && (
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="Qab7tttP5vw"
          onClose={() => setOpen(false)}
        />
      )} */}
      <section className="py-1 md:py-10">
        <div className="w-screen px-12 lg:flex lg:items-center">
          <div className="mb-5 mx-2 lg:mb-0 text-center lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Build data pipelines, tests, and docs{" "}
              <span className="text-yellow-400">with Jupyter notebooks</span>
            </h1>
            <p className="text-xl lg:text-2xl mt-6 font-light">
              data chimp is a VSCode plugin that turns Jupyter notebooks into a
              full-blown data and ML workspace. We're currently in open beta
              with some features still in closed beta.
            </p>
            <button
              className="flex-shrink-0 text-md border-4 text-white py-1 px-2 rounded-full bg-yellow-400 border-yellow-400 hover:border-black hover:bg-black font-extrabold mr-8 mb-4"
              type="submit"
            >
              <a
                className="no-underline font-bold"
                href="https://marketplace.visualstudio.com/items?itemName=data-chimp.data-chimp"
              >
                Download
              </a>
            </button>
            <button
              className="flex-shrink-0 text-md border-4 text-white py-1 px-2 rounded-full bg-black border-black hover:border-yellow-400 hover:bg-yellow-400 font-extrabold"
              type="submit"
            >
              <a
                className="no-underline font-bold"
                href="https://join.slack.com/t/datachimp/shared_invite/zt-1lagp105e-xN~i071c9mvX_VQ4aqMqPg"
              >
                Join the beta users slack
              </a>
            </button>
          </div>
          <div className="lg:w-1/2 mx-4">
            <img src={dabbing} />
          </div>
        </div>
      </section>
      <section className="py-20 bg-yellow-200">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/3 lg:order-2">
            <h1 className="text-2xl lg:text-3xl xl:text-4xl font-bold leading-none">
              Go from notebook cells to testable functions to pipelines in a few
              clicks
            </h1>
            <p className="text-l lg:text-xl mt-6 font-light">
              data chimp analyzes your code to turn a notebook cell into a
              isolated, unit-testable function. Then, it gives you a visual
              pipeline builder turn those functions into a pipeline that's run
              on your own infrastructure. data chimp doesn't need access to your
              code or database keys.
            </p>
          </div>
          <div className="lg:w-2/3 lg:px-9">
            <video
              className="my-3"
              src={pipeline}
              autoPlay
              muted
              loop
              controls
            />
          </div>
        </div>
      </section>
      <section className="py-20">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/3">
            <h1 className="text-2xl lg:text-3xl xl:text-4xl font-bold leading-none">
              Validate your data and ML models with automatically-run, pre-built
              checks or create your own with a Jupyter notebook
            </h1>
            <p className="text-l lg:text-xl mt-6 font-light">
              data chimp automatically runs configurable data and model checks
              as you work in your notebook AND as you run your pipelines.
            </p>
            <p className="text-l lg:text-xl mt-6 font-light">
              Here, data chimp is automatically showing a set of rows that
              violate a data assertion we made about petal_length.
            </p>
            <button
              className="flex-shrink-0 text-md border-4 text-white py-1 px-2 rounded-full bg-yellow-400 border-yellow-400 hover:border-black hover:bg-black font-extrabold mr-8"
              type="submit"
            >
              <a
                className="no-underline font-bold"
                href="/blog/functions-arent-enough"
              >
                Learn More
              </a>
            </button>
          </div>
          <div className="lg:w-2/3 lg:px-9">
            <video
              className="my-3"
              src={dataQuality}
              autoPlay
              loop
              muted
              controls
            />
          </div>
        </div>
      </section>
      <section className="py-20 bg-yellow-200">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/3 lg:order-2">
            <h1 className="text-2xl lg:text-3xl xl:text-4xl font-bold leading-none">
              Get and create data docs within your Jupyter notebooks
            </h1>
            <p className="text-l lg:text-xl mt-6 font-light">
              Isn't it insane that notebooks aren't used for data docs? Flag bad
              columns for your team or integrate with dbt docs.
            </p>
          </div>
          <div className="lg:w-2/3 lg:px-9">
            <video
              className="my-3"
              src={dbtDocs}
              autoPlay
              loop
              muted
              controls
            />
          </div>
        </div>
      </section>
      <section className="py-20">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center lg:text-left lg:w-1/3">
            <h1 className="text-2xl lg:text-3xl xl:text-4xl font-bold leading-none">
              Automate the boring parts of EDA
            </h1>
            <p className="text-l lg:text-xl mt-6 font-light">
              data chimp automatically generates configurable, contextual
              visualizations and statistics to help you explore your data
              faster. You can even send the code that generated a visualization
              back into a cell for further iteration. Think pandas profiling,
              but better.
            </p>
            <button
              className="flex-shrink-0 text-md border-4 text-white py-1 px-2 rounded-full bg-yellow-400 border-yellow-400 hover:border-black hover:bg-black font-extrabold mr-8"
              type="submit"
            >
              <a
                className="no-underline font-bold"
                href="/blog/functions-arent-enough"
              >
                Learn More
              </a>
            </button>
          </div>
          <div className="lg:w-2/3 lg:px-9">
            <video className="my-3" src={eda} autoPlay loop controls muted />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
